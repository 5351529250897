(function ($) {
	"use strict";
	$(document).ready(function() {
		var location = $("#thim-weather").attr("data-location");
		var unit = $("#thim-weather").attr("data-unit");

		$('.weather-temperature').openWeather({
			key       : 'c9d49310f8023ee2617a7634de23c2aa',
			city      : location,
			iconTarget: '.weather-icon',
			units     : unit,
			success   : function (data) {
				// show weather
				$('.weather-wrapper').show();
			},
			error     : function (data) {
				$('.weather-wrapper').remove();
			}
		});
	});

})(jQuery);

